import instance from "../utils/axios";

export const getUserBalance = async (data) => {
  // return await instance.post('/ledger/getUserBalance', data)
};


export const auraEventTypesWisePLNew = async (data) => {
  // return await instance.post('/reports/auraEventTypesWisePLNew', data)
};
